import axios from "axios";

export interface Payload {
  [key: string]: any;
}

const API = async (payload: Payload) => {
  const url =
    "https://process-api-01.azure-api.net/driverportal/manual/paths/invoke";

  try {
    const apiKey = process.env.REACT_APP_LOGIC_APP_KEY;

    const config = {
      headers: {
        "Ocp-Apim-Subscription-Key": apiKey,
      },
    };

    if (!apiKey) {
      throw new Error("API Key is not defined in environment variables");
    }

    const response = await axios.post(url, payload, config);

    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

export default API;
