import React from "react";
import { Flex, Tabs, TabList, Tab, TabIndicator } from "@chakra-ui/react";

interface ROVMenuTabsProps {
  setOpenTab: (tab: string) => void;
  tabIndex: number;
}

const ROVMenuTabs = ({ setOpenTab, tabIndex }: ROVMenuTabsProps) => {
  return (
    <Flex
      height="50px"
      width="100%"
      background="blackAlpha.900"
      blur="10px"
      justifyContent="center"
      alignItems="center"
      pb="10px"
      position="fixed"
      bottom="0"
      zIndex="1"
    >
      <Tabs position="relative" variant="unstyled" index={tabIndex}>
        <TabList>
          <Tab
            onClick={() => setOpenTab("Issues")}
            color="white"
            fontWeight="semibold"
          >
            Issues
          </Tab>
          <Tab
            onClick={() => setOpenTab("Repair Orders")}
            color="white"
            fontWeight="semibold"
          >
            Repair Orders
          </Tab>
        </TabList>
        <TabIndicator height="2px" bg="#EE7700" borderRadius="1px" />
      </Tabs>
    </Flex>
  );
};

export default ROVMenuTabs;
