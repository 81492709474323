import React from "react";
import { IssuesType } from "../../types/Types";
import { Flex, Heading, Badge, Text, VStack, Card } from "@chakra-ui/react";

interface IssuesCardProps {
  issue: IssuesType;
  onClick: (roNumber: string) => void;
}

const IssueCard: React.FC<IssuesCardProps> = ({ issue, onClick }) => {
  return (
    <Card
      p={5}
      cursor={issue.RoNumber ? "pointer" : "default"}
      onClick={() => issue.RoNumber && onClick(issue.RoNumber)}
      size="lg"
    >
      <VStack align="flex-start" spacing={2}>
        <Heading size="sm">Issue #{issue.ID || "N/A"}</Heading>
        <Badge
          colorScheme="gray"
          borderRadius="full"
          color="gray"
          fontWeight="semibold"
          pl="10px"
          pr="10px"
        >
          {issue.Tag || "N/A"}
        </Badge>
        <Flex>
          <Text
            fontSize="sm"
            color={issue.RoNumber ? "#246bff" : "orange"}
            fontWeight="semibold"
            mr="5px"
          >
            Repair Order
          </Text>
          <Text
            fontSize="sm"
            color={issue.RoNumber ? "#246bff" : "orange"}
            fontWeight="semibold"
          >
            {!issue.RoNumber ? "Not Available" : `#${issue.RoNumber}`}
          </Text>
        </Flex>
        <Flex>
          <Text fontSize="sm" fontWeight="semibold" mr="5px">
            Unit Type:
          </Text>
          <Text fontSize="sm">{issue.type || "N/A"}</Text>
        </Flex>
        <Flex>
          <Text fontSize="sm" fontWeight="semibold" mr="5px">
            Date Added:
          </Text>
          <Text fontSize="sm">
            {issue.DateAdded
              ? new Date(issue.DateAdded).toLocaleString("en-US", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: false,
                })
              : "N/A"}
          </Text>
        </Flex>
        <VStack width="100%" align="flex-start">
          <Text fontSize="sm" fontWeight="semibold" mr="5px">
            Notes:
          </Text>
          <div dangerouslySetInnerHTML={{ __html: issue.Notes || "N/A" }} />
          {issue.Tag && issue.Tag.includes("Terminal Pending") && (
            <Text color="red.600" fontSize="xs" fontWeight="semibold">
              * Please work with your driver leader to schedule an appointment
              at a Hirshbach Shop to address the issue.
            </Text>
          )}
        </VStack>
      </VStack>
    </Card>
  );
};

export default IssueCard;
