import React, { useState, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Flex,
  VStack,
  Text,
  Spinner,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { useFetchNote } from "../../hooks/fetchNote";
import { NoteProps } from "../../types/Types";
import { IoMdSend } from "react-icons/io";

import API from "../../api/driverQuery";

interface NoteAddingProps {
  selectedID: string;
  isOpen: boolean;
  driverCode: string;
  driverName: string;
  onClose: () => void;
}

const NoteAdding = ({
  selectedID,
  isOpen,
  onClose,
  driverCode,
  driverName,
}: NoteAddingProps) => {
  const focusRef = useRef(null);
  const [trigger, setTrigger] = useState(false);
  const { notes, isLoading } = useFetchNote({
    roNumber: selectedID,
    trigger: Boolean(trigger),
  });
  const [addedNote, setAddedNote] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const handleNoteSubmit = async () => {
    setIsSubmitting(true);
    setAddedNote("");
    try {
      const payload = {
        option: "enterROVNote",
        id: selectedID,
        content: "[" + driverCode + "]" + addedNote,
      };
      const response = await API(payload);
      if (response) {
        setTimeout(() => {
          setTrigger((prevtrigger) => !prevtrigger);
          toast({
            title: "Note added successfully",
            status: "success",
            duration: 3000,
            position: "top",
          });
          setIsSubmitting(false);
        }, 2000);
      }
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      finalFocusRef={focusRef}
      autoFocus={false}
      allowPinchZoom={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody overflow="hidden">
          <VStack align="flex-start" width="100%" spacing="20px">
            <Flex width="100%" align="center" p={2}>
              <Text fontSize="md" fontWeight="semibold">
                Repair Order #{selectedID}
              </Text>
              <Spinner
                size="sm"
                color="#EE7700"
                emptyColor="gray.200"
                ml={2}
                style={{ visibility: isLoading ? "visible" : "hidden" }}
              />
            </Flex>
            <Flex
              width="100%"
              align="flex-start"
              border="1px"
              borderColor={"gray.200"}
              borderRadius="xl"
            >
              <Textarea
                placeholder="Add note..."
                fontSize="xs"
                resize="none"
                sx={{
                  border: "none",
                  "&:hover": {
                    border: "none",
                  },
                  "&:focus": {
                    boxShadow: "none",
                    border: "none",
                  },
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  scrollbarWidth: "none",
                }}
                value={addedNote}
                onChange={(e) => setAddedNote(e.target.value)}
              />

              <IconButton
                aria-label="Send"
                icon={<IoMdSend />}
                variant="link"
                borderRadius="full"
                size="sm"
                m="10px"
                onClick={handleNoteSubmit}
                isLoading={isSubmitting}
              />
            </Flex>
            <VStack
              align="flex-start"
              width="100%"
              spacing="30px"
              height="100%"
            >
              <Text
                fontSize="xs"
                color="blackAlpha.700"
                justifySelf="center"
                alignSelf="center"
              >
                Latest Note &#8593;
              </Text>
              {notes &&
                notes.map((note: NoteProps) => {
                  return (
                    <VStack
                      key={note.note_id}
                      direction="column"
                      align={
                        note.added_by === "Hirschbach API" ||
                        !note.added_by ||
                        note.added_by.trim() === "" ||
                        note.added_by === driverCode
                          ? "flex-end"
                          : "flex-start"
                      }
                      spacing="2px"
                      width={"100%"}
                      tabIndex={-1}
                    >
                      <Text
                        fontSize="2xs"
                        color="gray.500"
                        mr={
                          note.added_by === "Hirschbach API" ||
                          !note.added_by ||
                          note.added_by.trim() === "" ||
                          note.added_by === driverCode
                            ? "2px"
                            : "auto"
                        }
                        ml={
                          note.added_by === "Hirschbach API" ||
                          !note.added_by ||
                          note.added_by.trim() === "" ||
                          note.added_by === driverCode
                            ? "auto"
                            : "2px"
                        }
                        tabIndex={-1}
                      >
                        {note.added_by === "Hirschbach API" ||
                        !note.added_by ||
                        note.added_by.trim() === "" ||
                        note.added_by === driverCode
                          ? driverName.toUpperCase()
                          : note.added_by.toUpperCase()}
                      </Text>
                      <Flex
                        maxW="90%"
                        direction="column"
                        align="flex-start"
                        p={2}
                        borderRadius={4}
                        bg={
                          note.added_by === "Hirschbach API" ||
                          !note.added_by ||
                          note.added_by.trim() === "" ||
                          note.added_by === driverCode
                            ? "blackAlpha.50"
                            : "blue.50"
                        }
                        tabIndex={-1}
                      >
                        <Text fontSize="xs" tabIndex={-1}>
                          {note.note}
                        </Text>
                      </Flex>
                      <Text
                        fontSize="2xs"
                        mr={
                          note.added_by === "Hirschbach API" ||
                          !note.added_by ||
                          note.added_by.trim() === "" ||
                          note.added_by === driverCode
                            ? "2px"
                            : "auto"
                        }
                        ml={
                          note.added_by === "Hirschbach API" ||
                          !note.added_by ||
                          note.added_by.trim() === "" ||
                          note.added_by === driverCode
                            ? "auto"
                            : "2px"
                        }
                        tabIndex={-1}
                      >
                        {note.date_added
                          ? new Date(note.date_added).toLocaleString()
                          : ""}
                      </Text>
                    </VStack>
                  );
                })}
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NoteAdding;
