import React, { useState } from "react";
import {
  Flex,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Text,
  Button,
  Stack,
  Divider,
  FormControl,
  FormLabel,
  Select,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { VscListFilter } from "react-icons/vsc";
import { IoIosRefresh } from "react-icons/io";

interface TopBarProps {
  activeTab: string;
  status: string;
  unitType: string;
  sort: string;
  tag: string;
  loading: boolean;
  roSpecific: string;
  statusHandler: (status: string) => void;
  unitHandler: (unit: string) => void;
  sortHandler: (sort: string) => void;
  tagHandler: (tag: string) => void;
  issueUnitType: string;
  issueOrder: string;
  issueUnitHandler: (unit: string) => void;
  issueOrderHandler: (order: string) => void;
  refetch?: () => void;
  roSpecificHandler: (ro: string) => void;
}

const TopBar = ({
  status,
  unitType,
  sort,
  tag,
  loading,
  activeTab,
  issueOrder,
  issueUnitType,
  roSpecific,
  issueOrderHandler,
  issueUnitHandler,
  statusHandler,
  unitHandler,
  sortHandler,
  tagHandler,
  refetch,
  roSpecificHandler,
}: TopBarProps) => {
  const [activeScreen, setActiveScreen] = useState("Repair Order Visibility");

  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose,
  } = useDisclosure();

  const {
    isOpen: isFilterOpen,
    onOpen: onFilterOpen,
    onClose: onFilterClose,
  } = useDisclosure();

  return (
    <>
      <Flex
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        padding="5px 10px"
        background="blackAlpha.900"
      >
        <Flex alignContent="center">
          <IconButton
            aria-label="menu"
            icon={<HamburgerIcon />}
            size="xl"
            variant="link"
            fontSize="30px"
            onClick={onMenuOpen}
            color="white"
          />
          <Image
            src="/logo.png"
            alt="logo"
            width="100px"
            height="100%"
            ml="20px"
          />
        </Flex>
        <Flex alignContent="center">
          <IconButton
            aria-label="filter"
            icon={<VscListFilter />}
            size="xl"
            variant="link"
            fontSize="30px"
            color="white"
            onClick={onFilterOpen}
            mr={2}
          />
          <IconButton
            aria-label="refresh"
            icon={<IoIosRefresh />}
            size="xl"
            variant="link"
            fontSize="30px"
            color="white"
            isLoading={loading}
            onClick={refetch}
          />
        </Flex>
      </Flex>

      <Drawer
        isOpen={isMenuOpen}
        placement="left"
        onClose={onMenuClose}
        size="xs"
      >
        <DrawerOverlay />
        <DrawerContent ml="-50px" pl="50px" bg="gray.50">
          <DrawerHeader>
            <Text fontSize="md">MENU</Text>
          </DrawerHeader>
          <DrawerBody>
            <Divider />
            <Stack
              spacing={5}
              mt={5}
              justifyContent="flex-start"
              alignItems={"flex-start"}
            >
              <Button
                variant="link"
                size="lg"
                textDecoration={"none"}
                _hover={{ textDecoration: "none" }}
                color={
                  activeScreen === "Repair Order Visibility"
                    ? "#246bff"
                    : "blackAlpha.700"
                }
                onClick={() => setActiveScreen("Repair Order Visibility")}
              >
                Repair Order Visibility
              </Button>
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <Text fontSize="xs" textAlign="center">
              Powered by Process Improvement ⚡
            </Text>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Drawer isOpen={isFilterOpen} placement="right" onClose={onFilterClose}>
        <DrawerOverlay />
        <DrawerContent mr="-50px" pr="50px" bg="gray.50">
          <DrawerHeader>
            <Text fontSize="md">FILTER</Text>
          </DrawerHeader>
          <DrawerBody>
            <Divider mb={4} />
            {activeTab === "Repair Orders" && (
              <div>
                {roSpecific === "" ? (
                  <FormControl>
                    <FormLabel fontSize="xs" mt={4}>
                      Unit Type
                    </FormLabel>
                    <Select
                      size="sm"
                      value={unitType}
                      onChange={(e) => unitHandler(e.target.value)}
                      variant="filled"
                      borderRadius="md"
                    >
                      <option key="all" value="All">
                        All
                      </option>
                      <option key="trailer" value="Trailer">
                        Trailer
                      </option>
                      <option
                        key="tandemAxleTractor"
                        value="Tandem Axle Tractor"
                      >
                        Tandem Axle Tractor
                      </option>
                      <option
                        key="refrigeratedTrailer"
                        value="Refrigerated Trailer"
                      >
                        Refrigerated Trailer
                      </option>
                    </Select>
                    <FormLabel fontSize="xs" mt={4}>
                      Tag
                    </FormLabel>
                    <Select
                      size="sm"
                      value={tag}
                      onChange={(e) => tagHandler(e.target.value)}
                      variant="filled"
                      borderRadius="md"
                    >
                      <option key="all" value="All">
                        All
                      </option>
                      <option
                        key="loadedAssetUnderDispatched"
                        value="Loaded Asset Under Dispatched"
                      >
                        Loaded Asset Under Dispatched
                      </option>
                      <option key="rebuild" value="Rebuild">
                        Rebuild
                      </option>
                      <option key="needsDetail" value="Needs Detail">
                        Needs Detail
                      </option>
                    </Select>
                    <FormLabel fontSize="xs" mt={4}>
                      Status
                    </FormLabel>
                    <Select
                      size="sm"
                      value={status}
                      onChange={(e) => statusHandler(e.target.value)}
                      variant="filled"
                      borderRadius="md"
                    >
                      <option key="open" value="Open">
                        Open
                      </option>
                      <option key="finished" value="Finished">
                        Finished
                      </option>
                      <option key="all" value="All">
                        All
                      </option>
                    </Select>
                    <FormLabel fontSize="xs" mt={4}>
                      Sort
                    </FormLabel>
                    <Select
                      size="sm"
                      value={sort}
                      onChange={(e) => sortHandler(e.target.value)}
                      variant="filled"
                      borderRadius="md"
                    >
                      <option key="descending" value="Descending">
                        Descending
                      </option>
                      <option key="ascending" value="Ascending">
                        Ascending
                      </option>
                    </Select>
                  </FormControl>
                ) : (
                  <Button
                    size="md"
                    colorScheme="gray"
                    borderRadius="full"
                    onClick={() => roSpecificHandler("")}
                  >
                    Clear RO #{roSpecific} Filter
                  </Button>
                )}
              </div>
            )}

            {activeTab === "Issues" && (
              <>
                <FormControl>
                  <FormLabel fontSize="xs" mt={4}>
                    Unit Type
                  </FormLabel>
                  <Select
                    size="sm"
                    value={issueUnitType}
                    onChange={(e) => issueUnitHandler(e.target.value)}
                    variant="filled"
                    borderRadius="md"
                  >
                    <option key="all" value="All">
                      All
                    </option>
                    <option key="trailer" value="Trailer">
                      Trailer
                    </option>
                    <option key="tandemAxleTractor" value="Tandem Axle Tractor">
                      Tandem Axle Tractor
                    </option>
                    <option
                      key="refrigeratedTrailer"
                      value="Refrigerated Trailer"
                    >
                      Refrigerated Trailer
                    </option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="xs" mt={4}>
                    Sort
                  </FormLabel>
                  <Select
                    size="sm"
                    value={issueOrder}
                    onChange={(e) => issueOrderHandler(e.target.value)}
                    variant="filled"
                    borderRadius="md"
                  >
                    <option key="descending" value="Descending">
                      Descending
                    </option>
                    <option key="ascending" value="Ascending">
                      Ascending
                    </option>
                  </Select>
                </FormControl>
              </>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default TopBar;
