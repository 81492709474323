import React, { useState, useEffect, useRef } from "react";
import API from "../api/driverQuery";
import { NoteProps } from "../types/Types";

interface FetchNoteProps {
  roNumber: string;
  trigger: boolean;
}

const useFetchNote = ({ roNumber, trigger }: FetchNoteProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [notes, setNotes] = useState<NoteProps[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let response = null;
        if (roNumber) {
          const payload = {
            searchTerm: roNumber,
            option: "fetchROVNotes",
          };
          response = await API(payload);
        }
        if (response && response.length > 0) {
          setNotes(response);
        } else {
          setNotes(null);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [roNumber, trigger]);

  return { isLoading, notes };
};

export { useFetchNote };
