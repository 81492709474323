import React from "react";
import { RepairOrderType } from "../../types/Types";
import {
  Card,
  Text,
  Badge,
  Flex,
  Stack,
  VStack,
  Divider,
} from "@chakra-ui/react";

interface ROCardProps {
  repairOrder: RepairOrderType;
  onClick: (id: string) => void;
}

const ROCard = ({ repairOrder, onClick }: ROCardProps) => {
  return (
    <Card
      p={5}
      size="lg"
      width="100%"
      onClick={() => onClick(repairOrder.id)}
      cursor="pointer"
    >
      <Stack direction="column">
        <Flex align="center">
          <Text fontSize="sm" fontWeight="bold">
            Repair Order #{repairOrder.id}
          </Text>

          <Badge
            tabIndex={-1}
            background={
              repairOrder.status === "Not Started"
                ? "gray.100"
                : repairOrder.status === "In Progress"
                ? "green.100"
                : repairOrder.status === "Finished"
                ? "blue.100"
                : "gray.100"
            }
            px={2}
            py={1}
            borderRadius="full"
            ml={2}
            fontSize="10px"
            fontWeight="semibold"
            color={
              repairOrder.status === "Not Started"
                ? "gray.600"
                : repairOrder.status === "In Progress"
                ? "green.600"
                : repairOrder.status === "Finished"
                ? "blue.600"
                : "gray.600"
            }
          >
            {repairOrder.status}
          </Badge>
        </Flex>
      </Stack>
      <Divider my={2} borderColor="blackAlpha.300" />

      <VStack align="start" justify="start">
        <Flex>
          <Text fontSize="sm" color="#246bff" fontWeight="semibold" mr="5px">
            Expected Finished Date:
          </Text>
          <Text fontSize="sm" color="#246bff" fontWeight="semibold">
            {repairOrder.date_expected_finish === "1899-12-31T18:00:00"
              ? "N/A"
              : new Date(repairOrder.date_expected_finish).toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: false,
                  }
                )}
          </Text>
        </Flex>
        <Flex>
          <Text fontSize="sm" fontWeight="semibold" mr="5px">
            Date Started:
          </Text>
          <Text fontSize="sm">
            {repairOrder.date_started === "1899-12-31T18:00:00"
              ? "N/A"
              : new Date(repairOrder.date_started).toLocaleString("en-US", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: false,
                })}
          </Text>
        </Flex>
        <Flex>
          <Text fontSize="sm" fontWeight="semibold" mr="5px">
            Date Finished:
          </Text>
          <Text fontSize="sm">
            {repairOrder.date_finished === "1899-12-31T18:00:00"
              ? "N/A"
              : new Date(repairOrder.date_finished).toLocaleString("en-US", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: false,
                })}
          </Text>
        </Flex>
        <Flex>
          <Text fontSize="sm" fontWeight="semibold" mr="5px">
            Vendor:
          </Text>
          <Text fontSize="sm">{repairOrder.vendor_name}</Text>
        </Flex>
      </VStack>
    </Card>
  );
};

export default ROCard;
