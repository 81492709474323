import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { Global, css } from "@emotion/react";
import App from "./App";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <ChakraProvider theme={theme}>
    <Global
      styles={css`
        * {
          -webkit-tap-highlight-color: transparent;
        }
      `}
    />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ChakraProvider>
);
