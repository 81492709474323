import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import API from "../api/driverQuery";
import { DriverInfoType } from "../types/Types";

// Custom hook to fetch driver info
const useFetchDriverInfo = () => {
  const { drivercode } = useParams();
  const [loadState, setLoadState] = useState("isLoading");
  const [driverInfo, setDriverInfo] = useState<DriverInfoType | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          searchTerm: drivercode,
          option: "Verify",
        };

        const response = await API(payload);
        if (response[0]) {
          setDriverInfo(response[0]);
          setLoadState("isSuccess");
        } else {
          setLoadState("isFailed");
        }
      } catch (error) {
        setLoadState("isFailed");
      }
    };

    fetchData();
  }, [drivercode]);

  return { loadState, driverInfo };
};

export { useFetchDriverInfo };
