import React from "react";
import { Center, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionCenter = motion(Center);
const MotionText = motion(Text);

const NoDriverCode = () => {
  return (
    <MotionCenter
      height="100%"
      width="100%"
      background="white"
      p="50px"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <MotionText fontSize="md" color="blackAlpha.900">
        Driver code invalid. Please contact support.
      </MotionText>
    </MotionCenter>
  );
};

export default NoDriverCode;
