import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import API from "../api/driverQuery";
import { RepairOrderType, IssuesType } from "../types/Types";

const useFetchRepairOrder = () => {
  const { drivercode } = useParams();
  const [dataLoading, setDataLoading] = useState(true);
  const [repairorderData, setRepairOrderData] = useState<RepairOrderType[]>([]);
  const [issuesData, setIssuesData] = useState<IssuesType[]>([]);
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");

  const fetchData = useCallback(async () => {
    setDataLoading(true);
    try {
      const payload = {
        searchTerm: drivercode,
        option: "fetchData",
      };

      const response = await API(payload);
      if (response) {
        if (
          response.RO &&
          response.RO.Table1 &&
          response.RO.Table1.length > 0
        ) {
          setRepairOrderData(response.RO.Table1);
        } else {
          setRepairOrderData([]);
        }

        if (
          response.Issues &&
          response.Issues.Table1 &&
          response.Issues.Table1.length > 0
        ) {
          setIssuesData(response.Issues.Table1);
        } else {
          setIssuesData([]);
        }

        const date = new Date();
        setLastUpdatedTime(date.toLocaleString());
      }
      setDataLoading(false);
    } catch (error) {
      console.error(error);
      setDataLoading(false);
    }
  }, [drivercode]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    dataLoading,
    repairorderData,
    issuesData,
    lastUpdatedTime,
    refetch: fetchData,
  };
};

export { useFetchRepairOrder };
