import React from "react";
import { Flex, Text } from "@chakra-ui/react";

interface ROVStatsProps {
  roCount: number;
  issuesCount: number;
  lastUpdatedTime: string;
}

const ROVStats = ({ roCount, issuesCount, lastUpdatedTime }: ROVStatsProps) => {
  return (
    <Flex
      background="blackAlpha.50"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      p="10px"
      height="30px"
    >
      <Text fontSize="xs" color="gray.500">
        Refreshed: {lastUpdatedTime}
      </Text>
      <Flex alignItems="center">
        <Text fontSize="xs" color="gray.500" p="5px">
          {issuesCount}⚠
        </Text>
        <Text fontSize="xs" color="gray.500" p="5px">
          {roCount}🔧
        </Text>
      </Flex>
    </Flex>
  );
};

export default ROVStats;
