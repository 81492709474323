import React from "react";
import { Spinner, Center, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionCenter = motion(Center);
const MotionSpinner = motion(Spinner);
const MotionText = motion(Text);

const LoadingScreen = () => {
  return (
    <MotionCenter
      height="100vh"
      width="100vw"
      background="white"
      justify="center"
      align="center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      position="relative"
    >
      <MotionSpinner
        size="xl"
        color="#EE7700"
        thickness="4px"
        speed="0.8s"
        emptyColor="gray.200"
      />
      <MotionText fontSize="xl" color="gray.500" marginLeft="4">
        Retrieving Information
      </MotionText>
    </MotionCenter>
  );
};

export default LoadingScreen;
