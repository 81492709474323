import React from "react";
import { Routes, Route } from "react-router-dom";
import { VStack } from "@chakra-ui/react";
import ROV from "./layouts/ROV";

const App = () => {
  return (
    <VStack height="100vh" width="100vw" background="white">
      <Routes>
        <Route path="/" element={<div />} />
        <Route path="/rov/:drivercode" element={<ROV />} />
      </Routes>
    </VStack>
  );
};

export default App;
