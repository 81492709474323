import React, { useEffect, useState } from "react";
import {
  VStack,
  Stack,
  Flex,
  useDisclosure,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";

// Custom Hooks
import { useFetchDriverInfo } from "../hooks/verifyDriverInfo";
import { useFetchRepairOrder } from "../hooks/fetchData";

// Child Components
import TopBar from "../components/rov/TopBar";
import LoadingScreen from "../components/global/LoadingScreen";
import NoDriverCode from "../components/global/NoDriverCode";
import ROVStats from "../components/rov/RovStats";
import ROVMenuTabs from "../components/rov/ROVMenuTabs";
import ROCard from "../components/rov/ROCard";
import NoteAdding from "../components/rov/NoteAdding";
import IssueCard from "../components/rov/IssueCard";

// Import Types
import {
  DriverInfoType,
  TabTypes,
  RepairOrderType,
  IssuesType,
} from "../types/Types";

const ROV = () => {
  const [roSpecific, setROSpecific] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedID, setSelectedID] = useState("");
  const [activeTab, setActiveTab] = useState<TabTypes>("Issues");
  const [roUnitType, setROUnitType] = useState("All");
  const [roStatus, setROStatus] = useState("Open");
  const [roSort, setROSort] = useState("Descending");
  const [roTag, setROTag] = useState("All");
  const [filteredAndSortedData, setFilteredAndSortedData] = useState<
    RepairOrderType[]
  >([]);
  const [filteredAndSortedIssues, setFilteredAndSortedIssues] = useState<
    IssuesType[]
  >([]);

  const [issueUnitType, setIssueUnitType] = useState("All");
  const [issueOrder, setIssueOrder] = useState("Descending");

  const {
    loadState,
    driverInfo,
  }: { loadState: string; driverInfo: DriverInfoType | null } =
    useFetchDriverInfo();

  const {
    dataLoading,
    repairorderData,
    issuesData,
    lastUpdatedTime,
    refetch,
  }: {
    dataLoading: boolean;
    repairorderData: RepairOrderType[];
    issuesData: IssuesType[];
    lastUpdatedTime: string;
    refetch: () => void;
  } = useFetchRepairOrder();

  // Function to handle tab change
  const handleTabChange = (tab: string | TabTypes) => {
    setActiveTab(tab as TabTypes);
  };

  // Function to open the NoteAdding modal
  const openNoteAdding = (id: string) => {
    setSelectedID(id);
    onOpen();
  };

  // Function to close the NoteAdding modal
  const closeNoteAdding = () => {
    setSelectedID("");
    onClose();
  };

  // Filter and Sort the Repair Orders
  useEffect(() => {
    const filterAndSortData = () => {
      let filteredData = repairorderData.slice();

      if (roSpecific !== "") {
        return filteredData.filter((order) => order.id.includes(roSpecific));
      }

      if (roStatus === "Open") {
        filteredData = filteredData.filter(
          (order) =>
            order.status === "Not Started" || order.status === "In Progress"
        );
      } else if (roStatus !== "All") {
        filteredData = filteredData.filter(
          (order) => order.status === roStatus
        );
      }

      if (roTag !== "All") {
        filteredData = filteredData.filter((order) => order.tag === roTag);
      }

      if (roUnitType !== "All") {
        filteredData = filteredData.filter(
          (order) => order.unit_type === roUnitType
        );
      }

      if (roSort === "Descending") {
        filteredData.sort(
          (a, b) =>
            new Date(b.date_created).getTime() -
            new Date(a.date_created).getTime()
        );
      } else {
        filteredData.sort(
          (a, b) =>
            new Date(a.date_created).getTime() -
            new Date(b.date_created).getTime()
        );
      }

      return filteredData;
    };

    setFilteredAndSortedData(filterAndSortData());
  }, [roStatus, roUnitType, roSort, roTag, repairorderData, roSpecific]);

  // Filter and Sort the Issues
  const filterAndSortIssues = () => {
    let filteredData = issuesData.slice();

    if (issueUnitType !== "All") {
      filteredData = filteredData.filter(
        (issue) => issue.type === issueUnitType
      );
    }

    if (issueOrder === "Descending") {
      filteredData.sort(
        (a, b) =>
          new Date(b.DateAdded).getTime() - new Date(a.DateAdded).getTime()
      );
    } else {
      filteredData.sort(
        (a, b) =>
          new Date(a.DateAdded).getTime() - new Date(b.DateAdded).getTime()
      );
    }

    return filteredData;
  };

  useEffect(() => {
    setFilteredAndSortedIssues(filterAndSortIssues());
  }, [issueUnitType, issueOrder, issuesData]);

  useEffect(() => {
    document.title = `ROV - ${activeTab}`;
  }, [activeTab]);

  return loadState === "isLoading" ? (
    <LoadingScreen />
  ) : loadState === "isFailed" ? (
    <NoDriverCode />
  ) : (
    <>
      <VStack height="100%" width="100%" spacing="0" background="white">
        {/* Top Bar */}
        <TopBar
          activeTab={activeTab}
          status={roStatus}
          unitType={roUnitType}
          sort={roSort}
          tag={roTag}
          statusHandler={setROStatus}
          unitHandler={setROUnitType}
          sortHandler={setROSort}
          tagHandler={setROTag}
          issueUnitType={issueUnitType}
          issueOrder={issueOrder}
          issueUnitHandler={setIssueUnitType}
          issueOrderHandler={setIssueOrder}
          loading={dataLoading}
          refetch={refetch}
          roSpecific={roSpecific}
          roSpecificHandler={setROSpecific}
        />

        {/* Stats, invisible when dataLoading */}
        <ROVStats
          roCount={filteredAndSortedData.length}
          issuesCount={filteredAndSortedIssues.length}
          lastUpdatedTime={lastUpdatedTime}
        />
        {activeTab === "Repair Orders" && roSpecific && (
          <Tag
            size="lg"
            key="All"
            borderRadius="full"
            variant="subtle"
            colorScheme="gray"
            color="gray.600"
            alignSelf="flex-start"
            ml="20px"
            mt="10px"
            visibility={roSpecific === "" ? "hidden" : "visible"}
          >
            <TagLabel>{roSpecific}</TagLabel>
            <TagCloseButton onClick={() => setROSpecific("")} />
          </Tag>
        )}

        {/* Menu Tabs */}
        {activeTab === "Repair Orders" && (
          <Stack
            width="100%"
            p="20px"
            direction="column"
            spacing="5px"
            overflow="auto"
            pb="100px"
            divider={<Flex width="1px" bg="gray.200" />}
          >
            {filteredAndSortedData.map((repairOrder) => (
              <ROCard
                key={repairOrder.id}
                repairOrder={repairOrder}
                onClick={openNoteAdding}
              />
            ))}
          </Stack>
        )}

        {/* Issues */}
        {activeTab === "Issues" && (
          <Stack
            width="100%"
            p="20px"
            direction="column"
            spacing="5px"
            overflow="auto"
            pb="100px"
            divider={<Flex width="1px" bg="gray.200" />}
          >
            {filteredAndSortedIssues.map((issue) => (
              <IssueCard
                key={issue.ID}
                issue={issue}
                onClick={(roSpecific) => {
                  setROSpecific(roSpecific);
                  setActiveTab("Repair Orders");
                }}
              />
            ))}
          </Stack>
        )}

        <ROVMenuTabs
          setOpenTab={handleTabChange}
          tabIndex={activeTab === "Issues" ? 0 : 1}
        />
      </VStack>
      <NoteAdding
        selectedID={selectedID}
        isOpen={isOpen}
        onClose={closeNoteAdding}
        driverCode={driverInfo?.DRIVER_CODE || ""}
        driverName={driverInfo?.DRIVER_NAME || ""}
      />
    </>
  );
};

export default ROV;
